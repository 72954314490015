import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: {
        path: '/accounts/'
      }
    },
    {
      path: '/login/',
      name: 'login',
      meta: {
        title: 'Login'
      },
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/ClientLogin.vue')
    },
    {
      path: '/incompatible-browser/',
      name: 'incompatible-browser',
      meta: {
        title: 'Incompatible Browser'
      },
      component: () => import('../views/ClientUnsupported.vue')
    },
    {
      path: '/accounts/',
      name: 'accounts',
      meta: {
        title: 'Accounts'
      },
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/ClientAccounts.vue')
    },
    {
      path: '/client/:id',
      name: 'client',
      meta: {
        title: 'Dashboard'
      },
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/ClientDashboard.vue')
    },
    {
      path: '/admin/client/new/',
      name: 'admin-client-new',
      meta: {
        title: 'Add Client'
      },
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AdminClientNew.vue')
    },
    {
      path: '/admin/client/:id',
      name: 'admin-client-edit',
      meta: {
        title: 'Edit Client'
      },
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AdminClientEdit.vue')
    },
    {
      path: '/admin/client/:id/update/:update',
      name: 'admin-client-update',
      meta: {
        title: 'Add/Edit Update'
      },
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AdminClientUpdate.vue')
    },
    {
      path: '/admin/global/update/:update',
      name: 'admin-global-update',
      meta: {
        title: 'Add/Edit Update'
      },
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AdminClientUpdate.vue')
    },
    {
      path: '/admin/audit/',
      name: 'admin-audit',
      meta: {
        title: 'Audit Logs'
      },
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AdminAudit.vue')
    },
    {
      path: '/admin/notification/',
      name: 'admin-notification',
      meta: {
        title: 'Notification Logs'
      },
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AdminNotification.vue')
    },
    {
      path: '/admin/settings/',
      name: 'admin-settings',
      meta: {
        title: 'Settings'
      },
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AdminSettings.vue')
    },
    {
      path: '/restricted/',
      name: 'restricted',
      meta: {
        title: 'Restricted'
      },
      component: () => import('../views/AdminRestricted.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      meta: {
        title: '404 Not Found'
      },
      component: () => import('../views/ClientNotFound.vue')
    }
  ]
})

export default router
