<script setup>
import { RouterView } from 'vue-router'
import { computed } from 'vue'
import gsap from 'gsap'
</script>

<script>
export default {
  data () {
    return {
      navhide: true,
      admin: !this.$admin,
      refresh: false,
      noticeHidden: localStorage.getItem('hideNotice') || false
    }
  },
  provide () {
    return {
      refresh: computed(() => this.refresh)
    }
  },
  watch: {
    $route (to, from) {
      this.navhide = ['accounts', 'login', 'incompatible-browser'].includes(to.name)
    }
  },
  methods: {
    logout () {
      localStorage.setItem('jwt', null)
      localStorage.setItem('clients', null)
      this.$router.push('/login')
    },
    refreshButton () {
      /* Anime the refresh button press */
      gsap.to(this.$refs.refreshButton, { y: 2, duration: 0.1, yoyo: true, repeat: 1 })
      this.refresh = !this.refresh
    },
    hideNotice () {
      localStorage.setItem('hideNotice', true)
      this.noticeHidden = true
    }
  }
}
</script>

<template :class="$route.name">
  <aside v-if="!noticeHidden">
    <h3>First time visiting the Hub?</h3>
    <p>We're in the process of onboarding our clients to our new <b>Client Hub</b>. Find out more about
      about the <b>Bamboo Nine Client Hub</b>, what's new and what we have planned for the future at <a target="_blank"
        href="https://www.bamboonine.co.uk/client-hub/">https://www.bamboonine.co.uk/client-hub/</a>
    </p>
    <button @click="hideNotice">Don't show this again</button>
  </aside>
  <nav>
    <router-link class="logo-main" id="logo-link" to="/accounts/"><img class="logo" src="@/assets/bn-logo-secondary.svg"
        alt="logo" /></router-link>
    <router-link class="logo-mark" id="logo-link" to="/accounts/"><img class="logo" src="@/assets/bn-logo-mark.svg"
        alt="logo" /></router-link>

    <router-link v-if="$route.name === 'accounts' && $admin >= 80" class="bn__button--admin icon"
      to="/admin/client/new"><img src="@/assets/icon-new.svg"></router-link>

    <button v-if="$route.name === 'accounts' && $admin === 100" class="bn__button--superadmin icon"
      @click="$bus.emit('nav-click-feed')"><img src="@/assets/icon-feed.svg"></button>

    <router-link v-if="$route.name === 'accounts' && $admin === 100" class="bn__button--superadmin icon"
      to="/admin/settings/"><img src="@/assets/icon-settings.svg"></router-link>

    <button v-if="$route.name === 'accounts'" class="bn__button icon" ref="refreshButton" @click="refreshButton"><img
        src="@/assets/icon-refresh.svg"></button>

    <router-link v-if="$route.name === 'client' && $admin >= 80" class="bn__button--admin icon"
      :to="`/admin/client/${$route.params.id}`"><img src="@/assets/icon-edit.svg"></router-link>

    <router-link class="bn__button icon" v-if="!navhide" to="/accounts/"><img
        src="@/assets/icon-home.svg"></router-link>

    <button class="bn__button" v-if="$route.name !== 'login' && $route.name !== 'incompatible-browser'"
      @click="logout">Logout</button>
  </nav>
  <RouterView />
</template>

<style scoped>
/* Aside */
aside{
  margin-top: calc(var(--space-s-l)* -1);
  margin-left: 0;
  margin-bottom: calc(var(--space-s));
  padding: var(--space-s) 0;
  color: var(--color-text-tertiary);
  width: 100%;
  font-weight: var(--font-weight-semi-bold);
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

aside:before {
  content: '';
  left: -100vw;
  width: 200vw;
  background: var(--color-quarternary);
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}
aside p{
  font-weight: 400;
}
aside p:last-of-type{
  /* margin-bottom:0; */
}
aside a {
  color: var(--color-text-tertiary);
  text-decoration: underline;
}
aside button {
  font-size:12px;
  text-transform: uppercase;
  font-weight:700;
  outline:0;
  border:0;
  text-decoration: underline;
  background:none;
  color: var(--color-text-tertiary);
  cursor: pointer;
  padding:0;
}
/* Nav styling */
nav {
  display: flex;
  gap: var(--space-2xs);
  margin-bottom: var(--space-xs-s);
  align-items: flex-end;
}

nav .logo {
  height: 32px;
}
.logo-main {
  display: grid;
}
nav .logo-mark {
  display: none;
}

nav *:nth-child(3) {
  margin-left: auto;
}

nav .icon {
  height: 31.59px;
  width: 31.59px;
  position: relative;
  box-sizing:border-box;
}

nav .icon img {
  height: 20px;
  position: absolute;
  left: 6px;
  top: 6px;
}
/* Switch logo mark and full logo for small sizes */
@media (max-width: 500px) {
  nav .logo-mark {
    display: grid;
  }

  nav .logo-main {
    display: none;
  }
}
</style>
