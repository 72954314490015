/* Unscoped styles */
import './assets/main.css'
import 'floating-vue/dist/style.css'

/* Vue */
import { createApp, nextTick } from 'vue'
import mitt from 'mitt'
import App from './App.vue'
import router from './router'

/* Resize obs */
import { VueWindowSizePlugin } from 'vue-window-size/plugin'

/* User auth */
import { jwtDecode } from 'jwt-decode'

/* Error logging */
import * as Sentry from '@sentry/vue'

/* Used for tooltips */
import FloatingVue from 'floating-vue'

/* Google Analytics */
import VueGtag from 'vue-gtag'

const app = createApp(App)

/* Setup global statics */
app.config.globalProperties.$api = 'https://auth.bamboonine.co.uk/' /* The API URL */
// app.config.globalProperties.$api = 'http://localhost:8080/' /* The API URL */
app.config.globalProperties.$clients = [] /* Empty array to store the decoded JWT */
app.config.globalProperties.window = window /* Expose the global window object to Vue */

const emitter = mitt()
app.config.globalProperties.$bus = emitter /* Create a vue3 compatible global event bus */

/* Add domain to body attr */
const domain = (new URL(window.location.href)).origin
document.body.setAttribute('domain', domain)

/* Update title to indicate staging domain */
if (domain.endsWith('.dev')) document.title = `[STAGING] ${document.title}`

/* Error and performance logging via Sentry */
Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  app,
  release: import.meta.env.VITE_GIT_VER, /* Add in the git hash as the release version */
  dsn: 'https://043e1ae806fd186a6942ebad697831f4@o4504020857913344.ingest.us.sentry.io/4507095386947584',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  tracesSampleRate: 1.0, /*  Capture 100% of the transactions for performance sampling */
  tracePropagationTargets: ['localhost', /^https:\/\/localghost\.com/, /^https:\/\/hub\.bamboonine.\.co\.uk/],
  replaysSessionSampleRate: 0.1, /* 10% sample rate */
  replaysOnErrorSampleRate: 1.0 /* 100% sample rate */
})

app.use(FloatingVue) /* Add tool tip support */
app.use(VueWindowSizePlugin) /* Add window size plugin */
app.use(router) /* Add router */
app.use(VueGtag, { config: { id: 'G-DV1V408J65' } }) /* GA4 */
app.mount('#app')

/**
 * Checks if the access token exists in the local storage.
 * @returns {boolean} Returns true if the access token exists, false otherwise.
 */
const accessCheckTokenExists = () => {
  const token = localStorage.getItem('jwt')

  /* Check local storage for a JWT */
  if (!token) return false

  /* Check if the JWT is valid */
  return validJwt(token)
}

/**
 * Checks if a JWT token is valid.
 *
 * @param {string} token - The JWT token to be validated.
 * @returns {boolean} - Returns true if the token is valid, otherwise false.
 */
const validJwt = (token) => {
  try {
    /* Decode the jwt */
    const decoded = jwtDecode(token)

    /* Check if the JWT is expired */
    if (decoded.exp < Date.now() / 1000) {
      localStorage.setItem('jwt', null)
      return false
    }

    /* If the version of the jwt is below x log the uesr out allowing us to update the tokens in the future */
    if (!decoded.ver || decoded.ver < 2) {
      console.log('Logging user out due to old JWT version')
      localStorage.setItem('jwt', null)
      return false
    }

    /* Set up global statics related to logged in user */
    app.config.globalProperties.$clients = localStorage.getItem('clients') ? JSON.parse(localStorage.getItem('clients')) : []
    app.config.globalProperties.$email = decoded.email /* The email of the user */
    app.config.globalProperties.$sha = decoded.sha /* The sha of the user email */

    /* Update Sentry with the user email */
    Sentry.setUser({ email: decoded.email })
    app.config.globalProperties.$admin = decoded.access
    // app.config.globalProperties.$admin = validateAccess(decoded.email) /* Enable admin features if the user belongs to bamboo nine */

    return true
  } catch (err) {
    /* Fails if the JWT is formed badly */
    localStorage.setItem('jwt', null)
    return false
  }
}

/* Before each route change, check if the user is logged in */
router.beforeEach((to, from, next) => {
  /**
   * Represents the access token check result.
   * @type {boolean}
  */
  const access = accessCheckTokenExists()

  /* Redirect to the login page if the user is not logged in */
  if (to.name !== 'login' && to.name !== 'incompatible-browser' && !access) {
    /* Update the attr element with the correct location */
    document.documentElement.setAttribute('route', 'login')
    /* Redirect the user */
    next({ name: 'login' })
    return
  }

  /* Redirect to the accounts page if the user is logged in and they attempt to access the login page */
  if (to.name === 'login' && access) {
    /* Update the attr element with the correct location */
    document.documentElement.setAttribute('route', 'accounts')
    /* Redirect the user */
    next({ name: 'accounts' })
    return
  }

  /* If we're trying to access an admin route and we're not an admin redirect to the no-entry page */
  if (to.path.startsWith('/admin/') && !app.config.globalProperties.$admin) {
    next({ name: 'restricted' })
    return
  }

  /* Add route name to the document element */
  document.documentElement.setAttribute('route', to.name)

  /* If we've visited the root URL and have been redirected to the acc. page */
  if ((to.name === 'accounts' && to.redirectedFrom?.path === '/')) {
    /* Check if the user has a single account */

    /* Decode their client data from the jwt */
    /* Get their client data from local storag */
    const jwt = app.config.globalProperties.$clients

    /* If the client only has access to a single dashboard take them to it directly, otherwise show them the accounts page */
    if (Object.values(jwt).length === 1) {
      next({ name: 'client', params: { id: Object.values(jwt)[0].id } })
    }
  }

  /* Continue request as expected without redirecting the user */
  next()
})

const DEFAULT_TITLE = 'Client Hub'
router.beforeEach((to) => {
  nextTick(() => {
    document.title = to.meta.title ? `${DEFAULT_TITLE} - ${to.meta.title}` : DEFAULT_TITLE
  })
})

/* Force a reload if the user encounters a dynamic import error due deployment caching */
router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes('Importing a module script failed')) {
    window.location = to.fullPath
  }
})
